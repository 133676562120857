import React from 'react'
import { Link, useParams } from 'react-router-dom'

const AbsatAppsListItem = ({app,user}) => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = (today.getMonth() + 1).toString().padStart(2, "0"); // Months start at 0!
    let dd = today.getDate().toString().padStart(2, "0");

    const _params = useParams();
    const _token = localStorage.getItem('token');
    const _urlNavigate = app.status ==='done' ? app.name === "financial" ?  `${app.baseUrl+yyyy+mm+dd}df86d49a735f26a6022893c27cd834e9` : `${app.baseUrl}/${_params.tenant}/token/${_token}` : '#';

    return (
        <>
        { 
            user?.my_apps && user?.my_apps?.length > 0  ? ( user?.my_apps?.includes(app?._id) ?
                <Link to={`${_urlNavigate}`}>
                    <div className={`h-40 overflow-hidden relative rounded-xl border py-5  flex flex-col  items-center transition duration-0 hover:duration-500 hover:transition-all  ${app.status ==='loading' ? 'app-loading border-slate-700' : 'app-active border-yellow-950 hover:font-bold hover:p-2 '}`}>
                        <img src={`/assets/icons/${app?.icon}`} className={`  w-16  `} />
                        <div className='absolute z-20 end-0 bottom-0 w-full bg-slate-800 secondary-color rounded-b-xl  h-16 border-t border-slate-600 flex items-center justify-center  text-slate-100 cursor-default'>{app.titleAr}</div>
                    </div>
                </Link> : <></> ) :
                <Link to={`${_urlNavigate}`}>
                <div className={`h-40 overflow-hidden relative rounded-xl border py-5  flex flex-col  items-center transition duration-0 hover:duration-500 hover:transition-all  ${app.status ==='loading' ? 'app-loading border-slate-700' : 'app-active border-yellow-950 hover:font-bold hover:p-2 '}`}>
                    <img src={`/assets/icons/${app?.icon}`} className={`  w-16  `} />
                    <div className='absolute z-20 end-0 bottom-0 w-full bg-slate-800 secondary-color rounded-b-xl  h-16 border-t border-slate-600 flex items-center justify-center  text-slate-100 cursor-default'>{app.titleAr}</div>
                </div>
                </Link>
        }
        </>
    )
}

export default AbsatAppsListItem