import React from 'react'
import { APPS } from '../../constants/AbsatApps.js'
import AbsatAppsListItem from './AbsatAppsListItem.jsx'

export const AbsatAppsList = ({tenant}) => {
 

   
    const custApps  = (tenant.apps && tenant.apps.length) > 0 ? tenant.apps : APPS
    // const custApps  =  APPS
    // if(!custApps[0]?._id){
    //     let apps = [];
    //     custApps.map(item => {
    //         custApps
    //     })
    // }
    const _user = JSON.parse(localStorage.getItem('user'));
    console.log('_userApps11 ',_user)

    return (
        <div className='grid grid-cols-5 gap-7 w-5/6 items-center justify-center '>
            {
                custApps.map(app => (
                    <AbsatAppsListItem key={app.name} user={_user} app={app}/>
                ))
            }
        </div>
    )
}
